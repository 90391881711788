import {withRouter} from 'next/router';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {compose} from 'redux';

import {withRedirectAuth} from 'hoc/withRedirectAuth';
import {setModalContent} from 'store/modals/Modals.actions';
import {OrganizationsReducer} from "store/organizations/Organizations.model";
import {SharedReducer} from 'store/shared/Shared.model';
import {AppState} from 'store/store';
import {
  login,
  resendInvitation,
  setForgotPassword,
  signUpUser,
  twoFAVerification
} from 'store/user/User.actions';
import {UserReducer} from 'store/user/User.model';
import {SET_RESEND_INVITATION} from 'store/user/User.reducers';
import Login from './Login.component';

export interface ILoginState {
  isLoading: SharedReducer['isLoading'];
  showResendInvitation: UserReducer['showResendInvitation'];
  showTwoFAVerification: UserReducer['showTwoFAVerification'];
  organizations: OrganizationsReducer['organizations'];
  userData: UserReducer['userData'];
  forgotPasswordEmailFound: UserReducer['forgotPasswordEmailFound'];
}

export interface ILoginActions {
  loginAction: typeof login;
  resendInvitation: typeof resendInvitation;
  twoFAVerification: typeof twoFAVerification;
  hideResendInvitation: () => void;
  setModalContent: typeof setModalContent;
  signUpAction: typeof signUpUser;
  setForgotPassword: typeof setForgotPassword;
}

const mapStateToProps = (state: AppState): ILoginState => ({
  isLoading: state.sharedReducer.isLoading,
  showResendInvitation: state.userReducer.showResendInvitation,
  showTwoFAVerification: state.userReducer.showTwoFAVerification,
  organizations : state.organizationsReducer.organizations,
  userData: state.userReducer.userData,
  forgotPasswordEmailFound: state.userReducer.forgotPasswordEmailFound,
});

const actionCreators: ILoginActions = {
  loginAction: login,
  signUpAction: signUpUser,
  setForgotPassword,
  resendInvitation,
  twoFAVerification,
  setModalContent,
  hideResendInvitation: () => SET_RESEND_INVITATION(false)
};

const enhance = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, actionCreators),
  withRedirectAuth({ redirectIf: 'authenticated', redirectPath: '/documents' }),
);

export default enhance(Login) as React.ComponentType;
