import { apiSdk } from '../activateApi';
import ApiRes from '../ApiRes';

export const apiOrganizations = {
  getAllOrganizations: async () => {
    return await ApiRes.customFetch(() => apiSdk.getOrganizations({}, {}));
  },
  createOrganization: async (name: string) => {
    return await ApiRes.customFetch(() => apiSdk.postOrganizations({}, { name: name }));
  },
};
