import dynamic from 'next/dynamic';
import { withRouter } from 'next/router';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withRestoreUserSession } from 'hoc/withRestoreUserSession';
import { withSessionAuthContext } from 'hoc/withSessionAuth';
import { ModalsReducer } from 'store/modals/Modals.model';
import { AppState } from 'store/store';
import { ITemplateUnauthenticated } from './TemplateUnauthenticated.component';

export const TemplateUnauthenticatedWithNoSSR = dynamic<ITemplateUnauthenticated>(
  import('./TemplateUnauthenticated.component') as any,
  {
    ssr: false,
  },
);
export interface ITemplateUnauthenticatedState {
  modalContent: ModalsReducer['content'];
}
const mapStateToProps = (state: AppState): ITemplateUnauthenticatedState => ({
  modalContent: state.modalsReducer.content,
});
const enhance = compose(
  connect(mapStateToProps),
  withRouter,
  withTranslation(),
  withSessionAuthContext(),
  withRestoreUserSession,
);

export default enhance(TemplateUnauthenticatedWithNoSSR) as any;
