import styles from './ActionButton.module.scss';

interface ActionButtonProps {
  color: 'secondary' | 'primary' | 'white';
  type: 'border' | 'fill' | 'transparent';
  onClick?: () => void;
  isDisabled?: boolean;
  isSubmit?: boolean;
  rounded?: boolean;
}

const ActionButton: React.FunctionComponent<ActionButtonProps> = ({
  children,
  color,
  type = 'border',
  onClick,
  isDisabled = false,
  isSubmit = false,
  rounded = false,
}) => {
  const mainColor = color === 'primary' ? 'var(--primary)' : 'var(--secondary)';

  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      disabled={isDisabled}
      onClick={onClick}
      className={[
        styles.container,
        styles[type],
        isDisabled ? styles.isDisabled : '',
        type === 'transparent' ? styles.transparent : '',
        color === 'primary' ? styles.hoverBlue : styles.hoverRed,
      ].join(' ')}
      style={{
        backgroundColor: type === 'fill' ? mainColor : type === 'border' ? 'transparent' : 'var(--white)',
        borderColor: type === 'transparent' ? '' : mainColor,
        color: type === 'fill' ? 'var(--white)' : mainColor,
        borderRadius: rounded ? 30 : 5,
      }}>
      {children}
    </button>
  );
};
export default ActionButton;
