import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  fetchActions: {
    method: any;
    params?: any;
    revalidate?: boolean;
  }[];
  isReady?: boolean;
}

const useReduxInit = ({ fetchActions, isReady = true }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    isReady &&
      fetchActions?.forEach(async (action) => {
        if (typeof action.revalidate != 'boolean' || action.revalidate) {
          const { method } = action;
          dispatch(method(action.params));
        }
      });
  }, [dispatch, fetchActions, isReady]);
};

export default useReduxInit;
