import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

type OptionRedirect = {
  redirectPath: string;
  redirectIf: 'authenticated' | 'unauthenticated';
};

type OptionNoRedirect = Record<string, never>;

export type RedirectAuthParams = OptionRedirect | OptionNoRedirect;

/**
 * Redirects user according to whether he is authenticated or not.
 * If `redirectIf` param is undefined, then it does not redirect user.
 * Needs to be used inside SessionAuthContext
 * @param redirectPath
 */
export const useRedirectAuth = (params: RedirectAuthParams) => {
  const { doesSessionExist } = useSessionContext();
  const router = useRouter();

  useEffect(() => {
    if (params.redirectIf && (params.redirectIf === 'authenticated' && doesSessionExist) || (params.redirectIf === 'unauthenticated' && !doesSessionExist)) {
      router.replace(params.redirectPath);
    }
  }, [router, doesSessionExist, params.redirectPath, params.redirectIf]);
};
