import { apiOrganizations } from 'services/apis/organizations';
import { SET_ORGANIZATIONS } from 'store/organizations/Organizations.reducers';
import { SET_IS_LOADING } from 'store/shared/Shared.reducers';
import { Snack } from '../../components/Snackbar/Snackbar.entity';
import ApiRes from '../../services/ApiRes';
import { customSwitch } from '../../utils/helpers';
import { SET_SNACKBAR } from '../errors/Errors.reducer';
import { AppDispatch } from '../store';

export const getOrganizations = () => {
  return async (dispatch: AppDispatch) => {
    const res: ApiRes = await apiOrganizations.getAllOrganizations();

    customSwitch(res.status, {
      200: () => {
        dispatch(SET_ORGANIZATIONS(res.data));
      },
      204: () => {
        return;
      },
      default: () => {
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
      },
    });
  };
};

export const createOrganization = (name: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_IS_LOADING(true));
    const res = await apiOrganizations.createOrganization(name);
    customSwitch(res.status, {
      201: () => {
        dispatch(getOrganizations());
        dispatch(SET_SNACKBAR(new Snack(true, 'apiMessages.organization.CreationSuccess', 'success')));
      },
      default: () => {
        dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
      },
    });

    dispatch(SET_IS_LOADING(false));
  };
};
