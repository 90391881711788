import React from 'react';

import { RedirectAuthParams, useRedirectAuth } from 'hooks/useRedirectAuth';

/**
 * HOC that wraps a component with `useRedirectAuth` hook call
 * @param params
 * @returns
 */
export const withRedirectAuth =
  (params: RedirectAuthParams) =>
  <P,>(WrappedComponent: React.ComponentType<P>) => {
    return (props: P) => {
      useRedirectAuth(params);
      return <WrappedComponent {...props} />;
    };
  };
