import Head from 'next/head';
import React from 'react';

import Login from 'layouts/main/Login';
import { TemplateUnauthenticated } from 'templates/TemplateUnauthenticated';
import useReduxInit from "modules/ReduxInit/useReduxInit";
import {getOrganizations} from "store/organizations/Organizations.actions";

const LoginPage: React.FC = () => {

  useReduxInit({
    fetchActions: [{ method: getOrganizations, params: {} }],
  });

  return (
    <>
      <Head>
        <title>Connexion</title>
      </Head>
      <TemplateUnauthenticated>
        <Login />
      </TemplateUnauthenticated>
    </>
  );
};
export default LoginPage;
