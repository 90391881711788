import React, { useRef } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import { ActionButton } from 'components/Button/ActionButton';
import { isEmailValid } from 'utils/helpers';
import { upFChar } from 'utils/textFormatting';

import styles from '../Login.module.scss';

export interface Errors {
  email?: string;
}

export default function ForgotPassword({ setForgotPassword, isLoading, setFormToDisplay }) {
  const { t } = useTranslation();
  const submitRef = useRef();

  const onSubmit = (values) => {
    setForgotPassword(values.email);
  };

  return (
    <div>
      <div className={styles.arrowBack} onClick={() => setFormToDisplay('login')}>
        <ArrowBack />
      </div>
      {/* <h1>{upFChar(t('auth.welcomeSecbio'))}</h1> */}
      <p>{upFChar(t('auth.forgotPasswordText'))}</p>
      <Form
        onSubmit={(values) => onSubmit(values)}
        validate={(values) => {
          const errors: Errors = {};
          if (!isEmailValid(values.email)) {
            errors.email = 'Valid email required';
          }
          if (!values.email) {
            errors.email = 'Email required';
          }
          return errors;
        }}>
        {(props) => (
          <form onSubmit={props.handleSubmit} noValidate className={styles.form}>
            <Field name="email">
              {(props) => (
                <>
                  <label>{upFChar(t('auth.email'))}</label>
                  <TextField
                    name={props.input.name}
                    value={props.input.value}
                    onChange={(e) => {
                      props.input.onChange(e);
                    }}
                    style={{
                      marginTop: 6,
                      marginBottom: 18,
                      width: '-webkit-fill-available',
                    }}
                    margin="normal"
                    placeholder={t('auth.placeHolders.email')}
                    fullWidth
                    variant={'outlined'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required={true}
                    error={props.meta.error && props.meta.touched}
                    helperText={props.meta.error && props.meta.touched && props.meta.error}
                  />
                </>
              )}
            </Field>

            <div style={{ width: '100%' }}>
              <input ref={submitRef} type="submit" style={{ visibility: 'hidden' }} />
              <ActionButton
                // isSubmit
                type={'fill'}
                color={'primary'}
                isDisabled={isLoading}
                onClick={() => submitRef?.current.click()}>
                {t('pages.forgotPass.sendResetPass')}
              </ActionButton>
            </div>
          </form>
        )}
      </Form>
      <div className={styles.signUp}>
        {t('auth.loginPre')}{' '}
        <p
          className={styles.signUpCTA}
          onClick={() => setFormToDisplay('login')}
          // setModalContent({
          //   properties: {
          //     size: 'large',
          //     type: 'signUpUser',
          //   },
          //   header: {
          //     title: upFChar(t('pages.dashboard.allUsers.modal.signUpUser.title')),
          //     subTitle: upFChar(t('pages.dashboard.allUsers.modal.signUpUser.subtitle')),
          //   },
          //   data: {
          //     action: () => console.log('update'),
          //     userData: null,
          //   } as ModalUpdateUserContent,
          // })
        >
          {upFChar(t('auth.loginAction'))}
        </p>
      </div>
    </div>
  );
}
