import { NextRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Button, TextField } from '@material-ui/core';

import { ActionButton } from 'components/Button/ActionButton';
import { ModalTwoFAContent } from 'store/modals/Modals.model';
import { IUser } from 'store/user/User.model';
import { isEmailValid } from 'utils/helpers';
import { upFChar } from 'utils/textFormatting';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import Signup from './Signup/Signup';
import { ILoginActions, ILoginState } from './Login.wrap';

import styles from './Login.module.scss';

export interface Errors {
  email?: string;
  password?: string;
}

interface LoginProps extends ILoginActions, ILoginState {
  t: (tranlation: string) => string;
  loginError: boolean;
  router: NextRouter;
}

const Login: React.FC<LoginProps> = ({
  t,
  loginAction,
  signUpAction,
  loginError,
  isLoading,
  showResendInvitation,
  resendInvitation,
  showTwoFAVerification,
  hideResendInvitation,
  userData,
  setForgotPassword,
  setModalContent,
  organizations,
}) => {
  const [formToDisplay, setFormToDisplay] = useState('login');
  const [userEmail, setUserEmail] = useState<IUser['email']>();

  useEffect(() => {
    if (showTwoFAVerification)
      setModalContent({
        properties: {
          size: 'little',
          type: 'twoFA',
        },
        header: {
          title: upFChar(t(`components.modals.header.titles.${'twoFA'}`)),
          subTitle: upFChar(t(`components.modals.header.subTitles.${'twoFA'}`)),
        },
        data: {
          userEmail: userEmail,
        } as ModalTwoFAContent,
      });
    else setModalContent(null);
  }, [showTwoFAVerification, isLoading, setModalContent, t, userEmail]);

  const onSubmit = (values) => {
    if (showResendInvitation) {
      resendInvitation(values.email);
    } else {
      setUserEmail(values.email);
      loginAction({
        email: values.email,
        password: values.password,
        captchaToken: 'a',
      });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // const captchaValue = recaptcha?.current?.getValue();
    /*   if (false) {
      console.log(t('errors.unauthorized.MISSING_RECAPTCHA_ERROR'));
      store.dispatch(SET_SNACKBAR(new Snack(true, 'errors.unauthorized.MISSING_RECAPTCHA_ERROR', 'error', 30000)));
    } else {

    }*/
  };

  const submitRef = useRef(null);

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <img src="/assets/logo_SECBIO.svg" alt="secbio logo" className={styles.logo} />
        <div className={styles.formInnerContainer}>
          {formToDisplay === 'login' && (
            <>
              <h1>{upFChar(t('auth.welcomeSecbio'))}</h1>
              <p>{upFChar(t('auth.welcomeText'))}</p>
              <Form
                onSubmit={(values) => onSubmit(values)}
                validate={(values) => {
                  const errors: Errors = {};
                  if (!values.password) {
                    errors.password = 'Password required';
                  }
                  if (!isEmailValid(values.email)) {
                    errors.email = 'Valid email required';
                  }
                  if (!values.email) {
                    errors.email = 'Email required';
                  }
                  return errors;
                }}>
                {(props) => {
                  return (
                    <form onSubmit={props.handleSubmit} noValidate className={styles.form}>
                      {loginError && <p className={styles.mainError}>{t('auth.error')}</p>}
                      <Field name="email">
                        {(props) => (
                          <>
                            <label>{upFChar(t('auth.email'))}</label>
                            <TextField
                              name={props.input.name}
                              value={props.input.value}
                              onChange={(e) => {
                                showResendInvitation && hideResendInvitation();
                                props.input.onChange(e);
                              }}
                              style={{
                                marginTop: 6,
                                marginBottom: 18,
                                width: '-webkit-fill-available',
                              }}
                              margin="normal"
                              placeholder={t('auth.placeHolders.email')}
                              fullWidth
                              variant={'outlined'}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required={true}
                              error={props.meta.error && props.meta.touched}
                              helperText={props.meta.error && props.meta.touched && props.meta.error}
                            />
                          </>
                        )}
                      </Field>
                      <Field name="password">
                        {(props) => (
                          <>
                            <label>{upFChar(t('auth.password'))}</label>
                            <TextField
                              type={'password'}
                              name={props.input.name}
                              value={props.input.value}
                              onChange={props.input.onChange}
                              style={{
                                marginTop: 6,
                                marginBottom: 18,
                                width: '-webkit-fill-available',
                              }}
                              placeholder={t('auth.placeHolders.password')}
                              margin="normal"
                              fullWidth
                              variant={'outlined'}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className={props.input.value?.length > 0 ? styles.passwordFont : ''}
                              // required={true}
                              error={props.meta.error && props.meta.touched}
                              helperText={props.meta.error && props.meta.touched && props.meta.error}
                            />
                          </>
                        )}
                      </Field>
                      <div className={styles.submitContainer}>
                        <p className={styles.helpPassword} onClick={() => setFormToDisplay('forgotPassword')}>
                          {t('auth.forgotPassword')}
                        </p>

                        {showResendInvitation ? (
                          <Button
                            type={'submit'}
                            variant="contained"
                            color="primary"
                            disabled={isLoading || Boolean(Object.keys(props?.errors).length) || Boolean(userData)}>
                            {t('auth.resendInvitation')}
                          </Button>
                        ) : (
                          <div style={{ width: '100%' }}>
                            <input ref={submitRef} type="submit" style={{ visibility: 'hidden' }} />
                            <ActionButton
                              type="fill"
                              color="primary"
                              isDisabled={isLoading || Boolean(Object.keys(props?.errors).length) || Boolean(userData)}
                              onClick={() => submitRef?.current.click()}>
                              {t('auth.loginAction')}
                            </ActionButton>
                          </div>
                        )}
                      </div>
                    </form>
                  );
                }}
              </Form>
              <div className={styles.signUp}>
                {t('auth.signUpPre')}{' '}
                <p
                  className={styles.signUpCTA}
                  onClick={() => setFormToDisplay('signup')}
                  // setModalContent({
                  //   properties: {
                  //     size: 'large',
                  //     type: 'signUpUser',
                  //   },
                  //   header: {
                  //     title: upFChar(t('pages.dashboard.allUsers.modal.signUpUser.title')),
                  //     subTitle: upFChar(t('pages.dashboard.allUsers.modal.signUpUser.subtitle')),
                  //   },
                  //   data: {
                  //     action: () => console.log('update'),
                  //     userData: null,
                  //   } as ModalUpdateUserContent,
                  // })
                >
                  {upFChar(t('auth.signUpAction'))}
                </p>
              </div>
            </>
          )}
          {formToDisplay === 'signup' && (
            <Signup
              signUpAction={signUpAction}
              isLoading={isLoading}
              setFormToDisplay={setFormToDisplay}
              organizations={organizations}
            />
          )}
          {formToDisplay === 'forgotPassword' && (
            <ForgotPassword
              setForgotPassword={setForgotPassword}
              isLoading={isLoading}
              setFormToDisplay={setFormToDisplay}
            />
          )}
        </div>
      </div>
      <div className={styles.imageContainer} />
    </div>
  );
};

export default Login;
